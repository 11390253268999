//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: { currentSection: { type: Number, required: true, default: 0 } },
  data() {
    return {
      prefersReducedMotion: false,
      isShowingMenu: false,
      prevAnimation: null,
      navigationalLinks: [
        { label: 'Home', action: () => this.scrollTo(0) },
        { label: 'Galería', action: () => this.scrollTo('.works') },
        { label: 'Imágenes Ausentes', action: () => this.scrollTo('.about') },
        { label: 'Contacto', action: () => this.scrollTo('.contact') },
      ],
      socialLinks: [
        {
          label: 'instagram',
          href: 'https://www.instagram.com/imagenesausentes',
        },
        { label: 'facebook', href: 'https://www.facebook.com/Im%C3%A1genes-Ausentes-113007490882282' },
        { label: 'email', href: 'mailto:queesunaimagenausente@gmail.com' },
      ],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

      this.$nuxt.$on('toggle-menu', (bool) => {
        if (typeof bool === 'boolean' && this.isShowingMenu === bool) return

        if (this.isShowingMenu) {
          // this.$enableScrollY()
          this.hideMenu()
        } else {
          // this.$disableScrollY()
          this.showMenu()
        }

        this.isShowingMenu = !this.isShowingMenu
      })
    },
    showMenu() {
      if (this.prevAnimation) this.prevAnimation.kill()

      if (this.prefersReducedMotion)
        return (this.prevAnimation = this.$gsap.fromTo(this.$refs.menu, { autoAlpha: 0 }, { autoAlpha: 1 }))

      const tl = this.$gsap.timeline()
      this.prevAnimation = tl

      tl.set(this.$refs.menu, {
        autoAlpha: 1,
      })

      tl.fromTo(
        '.menu__back-item',
        {
          yPercent: 100,
          rotateX: -90,
          opacity: 0,
          transformOrigin: 'center top',
          transformStyle: 'preserve-3d',
        },
        {
          yPercent: 0,
          rotateX: 0,
          opacity: 1,
          stagger: 0.05,
        }
      )
      tl.fromTo(this.$refs.menu, { backgroundColor: 'transparent' }, { backgroundColor: '#030303' })
      tl.fromTo(
        '.menu__back-item__line',
        { transform: 'scaleX(0)', transformOrigin: 'left bottom' },
        {
          transform: 'scaleX(1)',
          stagger: 0.05,
        },
        '<'
      )
      tl.fromTo(
        '.menu__back-item__content__title, .menu__back-item__content__links__item',
        { opacity: 0, yPercent: 100 },
        {
          opacity: 1,
          yPercent: 0,
          duration: 1,
          ease: 'power4.out',
          stagger: 0.075,
        },
        '<-0.125'
      )
    },
    hideMenu() {
      if (this.prevAnimation) this.prevAnimation.kill()

      if (this.prefersReducedMotion)
        return (this.prevAnimation = this.$gsap.to(this.$refs.menu, {
          autoAlpha: 0,
        }))

      this.prevAnimation = this.$gsap.to(this.$refs.menu, {
        autoAlpha: 0,
      })
    },
    scrollTo(location) {
      const gsap = this.$gsap
      gsap.to(window, {
        scrollTo: {
          y: location,
        },
        duration: 2,
        ease: 'power3.inOut',
      })
    },
  },
}
